(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-transfer-rows/assets/utils/services.js') >= 0) return;  svs.modules.push('/components/payment/middleware-transfer-rows/assets/utils/services.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  getDrawName
} = svs.utils.products;
const fetchOpenedDrawsPromise = productId => {
  const drawAPIPath = getDrawName(productId);
  return new Promise((resolve, reject) => {
    jupiter.get("/draw/1/".concat(drawAPIPath, "/draws"), response => {
      resolve(response.draws);
    }, error => {
      reject(error);
    });
  });
};
setGlobal('svs.components.payment.middleware.transferRows.services', {
  fetchOpenedDrawsPromise
});

 })(window);