(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-transfer-rows/assets/utils/utils.js') >= 0) return;  svs.modules.push('/components/payment/middleware-transfer-rows/assets/utils/utils.js');
"use strict";


const TRANSFERRING_QUERY_PARAM = 'transfer';
const {
  CouponQueryParams
} = svs.components.sport.tipsenShared;
const {
  getGameUrl
} = svs.components.lbUtils;
const {
  STRYKTIPSET,
  T8EXTRA,
  T8STRYK,
  EUROPATIPSET
} = svs.utils.products.productIds;
const rowsStringDelimiter = ',';
const rowStringDelimiter = ':';
const signsStringDelimiter = ';';
const signIndexToOutcome = {
  0: '1',
  1: 'X',
  2: '2'
};
const convertOutcomesToString = outcomes => {
  const string = outcomes.map((s, index) => {
    const signs = s.flat();
    const concatenatedSigns = signs.map(signIndex => "".concat(signIndexToOutcome[signIndex])).join(signsStringDelimiter);
    return "".concat(index + 1).concat(rowStringDelimiter).concat(concatenatedSigns);
  }).filter(Boolean).join(rowsStringDelimiter);
  return string;
};
const getTransferUrl = (outcomeStrings, gameUrlOptions, relatedDraw) => {
  const searchParams = new URLSearchParams();
  searchParams.set(TRANSFERRING_QUERY_PARAM, 1);
  let gameUrl = getGameUrl({
    productId: relatedDraw.productId,
    drawNumber: relatedDraw.drawNumber,
    options: gameUrlOptions
  });
  outcomeStrings.forEach(outcomes => {
    searchParams.append(CouponQueryParams.PARAM_SIGNS, decodeURIComponent(outcomes));
  });
  gameUrl += "".concat(gameUrl.includes('?') ? '&' : '?').concat(decodeURIComponent(searchParams));
  return gameUrl;
};
const relatedProducts = {
  [STRYKTIPSET]: T8STRYK,
  [T8STRYK]: STRYKTIPSET,
  [EUROPATIPSET]: T8EXTRA,
  [T8EXTRA]: EUROPATIPSET
};
const getRelatedProductId = productId => relatedProducts[productId];
const SubProductId = {
  VM_TIPSET: 3,
  EM_TIPSET: 4
};
const subProductId2ToDisplayName = {
  [SubProductId.EM_TIPSET]: 'EM-Tipset',
  [SubProductId.VM_TIPSET]: 'VM-Tipset'
};
const userProduct2SubProductId = {
  EmTipset: SubProductId.EM_TIPSET,
  VmTipset: SubProductId.VM_TIPSET
};
const getSubProductDisplayName = userProduct => {
  if (!userProduct || !userProduct2SubProductId[userProduct]) {
    return undefined;
  }
  return subProductId2ToDisplayName[userProduct2SubProductId[userProduct]];
};
const TRACKING_ACTIONS = {
  TRANSFER: 'transfer',
  CANCEL_TRANSFER: 'cancel transfer'
};
setGlobal('svs.components.payment.middleware.transferRows.utils', {
  getTransferUrl,
  convertOutcomesToString,
  getRelatedProductId,
  TRACKING_ACTIONS,
  getSubProductDisplayName
});

 })(window);