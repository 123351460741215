(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-transfer-rows/assets/transfer.js') >= 0) return;  svs.modules.push('/components/payment/middleware-transfer-rows/assets/transfer.js');
'use strict';

const {
  getDisplayName
} = svs.utils.products;
const {
  convertOutcomesToString,
  getTransferUrl,
  getRelatedProductId,
  TRACKING_ACTIONS,
  getSubProductDisplayName
} = svs.components.payment.middleware.transferRows.utils;
const {
  analytics
} = svs.components;
const {
  logger
} = svs.components.payment.common;
const trackEvent = _ref => {
  let {
    trackingAction,
    paidProductId
  } = _ref;
  const trackingProductName = getDisplayName(paidProductId);
  analytics.trackEvent({
    category: 'overlays',
    action: trackingAction,
    opt_label: trackingProductName
  });
};

const transferRows = (onInteraction, _ref2) => {
  let {
    gameUrlOptions
  } = _ref2;
  return _ref3 => {
    let {
      payment
    } = _ref3;
    return async next => {
      const {
        result
      } = payment.paymentResponse;
      const paidItems = result.getPaidPayableItems();
      if (!paidItems.length) {
        return next();
      }
      const productBet = paidItems[0].getProductBet;
      const relatedProductId = getRelatedProductId(productBet.productId);
      if (!relatedProductId) {
        return next();
      }

      const openDraws = await svs.components.payment.middleware.transferRows.services.fetchOpenedDrawsPromise(relatedProductId);
      if (!openDraws.length) {
        return next();
      }
      const relatedDraw = openDraws.find(draw => draw.productId === relatedProductId);
      if (!relatedDraw) {
        return next();
      }
      const paidProductIds = [...new Set(paidItems.reduce((products, wager) => products.concat(wager.getProductBet.productId), []))];
      const outcomestring = productBet.boards.map(board => convertOutcomesToString(board.outcomes));
      const displayName = getSubProductDisplayName(relatedDraw === null || relatedDraw === void 0 ? void 0 : relatedDraw.userProduct) || getDisplayName(relatedProductId);
      const gameUrl = getTransferUrl(outcomestring, gameUrlOptions, relatedDraw);
      const title = "Vill du \xF6verf\xF6ra dina rader till ".concat(displayName, "?");
      const onCallback = action => {
        if (action === TRACKING_ACTIONS.TRANSFER) {
          logger.info("User accepted transferring rows, gameUrl: ".concat(gameUrl));
        }
        trackEvent({
          trackingAction: action,
          paidProductId: paidProductIds[0]
        });
      };
      logger.info("Showing dialog to transfer rows");
      await onInteraction({
        transferUrl: gameUrl,
        title,
        onCallback
      });
      next();
    };
  };
};
setGlobal('svs.components.payment.middleware.transferRows', {
  transferRows
});

 })(window);